<template>
  <div class="pressure">
    <div class="pressure-box">
      <div class="pressure-item">
        <div >
        <el-form :inline="true" class="searchBoxForm" size="mini">
        <el-form-item label="名称" class="searchItem">
            <el-input
              size="mini"
              v-model="pageParams.name"
              placeholder="请输入"
              clearable
              class="search"
            ></el-input>
          </el-form-item>
          <el-form-item label="隐蔽工程类型" class="searchItem">
            <el-cascader
                v-model="typeIds2"
                :options="typeIdList"
                :props = "{label:'name',value:'id'}"
                @change="getList"
                ></el-cascader>
          </el-form-item>
          <el-form-item label="登记日期" class="searchItem">
           <el-date-picker
              size="small"
              v-model="dataTime"
              type="daterange"
              value-format="yyyy-MM-dd"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="状态" class="searchItem">
            <el-select
                v-model="pageParams.statusCode"
                placeholder="请选择"
                size="mini"
                clearable
                @change="getList"
            >
              <el-option
                  v-for="(item, index) in statusCodeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属图层" class="searchItem">
              <el-cascader
                    v-model="pageParams.layerId"
                    :options="optionsList"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                     @change="getList"
                  >
                  </el-cascader>
           
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="getList" size="mini" >查询</el-button>
             <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
             <el-button type="success" @click="exportFile" size="mini" >导出</el-button>
          </el-form-item>
        </el-form>
      </div>
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin-top: 10px ;"
            border
            :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
            height="calc(100vh - 370px)"
            :stripe="true"
          >
          <el-table-column type="index"  width="50" label="序号"> </el-table-column>
          <el-table-column prop="name"  width="200px" label="名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="typeName"  width="150px" label="隐蔽工程类型" show-overflow-tooltip></el-table-column>
          <el-table-column prop="point"  width="100px" min-width="100px" label="经纬度" show-overflow-tooltip>
            <template slot-scope="{ row }">
            {{row.pointArray[0][0]+' '+row.pointArray[0][1]}}
            </template>
          </el-table-column>
          <el-table-column prop="depth" width="100px" label="埋深" show-overflow-tooltip></el-table-column>
          <el-table-column   width="80px" label="工程图片" show-overflow-tooltip>
             <template slot-scope="{ row }">
            <el-button size="small" type="text" plain  @click="showImg(row,1)" >查看</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="remarks"  label="备注" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createBy" width="120px" label="登记人" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" width="130px"  label="登记日期" show-overflow-tooltip></el-table-column>
          <el-table-column prop="statusName" width="80px" label="状态" show-overflow-tooltip>
            <template slot-scope="{ row }">
            <el-tag type="success" v-if=" row.statusName=='正常'">{{row.statusName}}</el-tag>
            <el-tag type="danger" v-else>{{row.statusName}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="layerName" width="130px" label="所属图层" show-overflow-tooltip></el-table-column>
          

          <el-table-column  width="120" label="操作">
            <template #default="{ row}">
            
              <div  >
              <span class="btn" style="color: #0A8E78" @click="dingwei(row)"> 定位 </span>
              <span class="btn" style="color: #1e9fff" @click="edit(row)"> 查看 </span>
                <span class="btn"  style="color: #FF8610" @click="getDelete(row)">
                  作废
                </span>
                
              </div>
             
            </template>
          </el-table-column>
        </el-table>
        <div class="total">
        <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
      </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      title="隐蔽工程"
      custom-class="dialog-class"
    >
      <el-form ref="addform" :model="form" label-width="120px"  :rules="hiddenRules" style="width:100%" size="mini">
          <div >
            <el-form-item label="所属图层:" >
              {{form.layerName}}
            </el-form-item>
            <el-form-item label="隐蔽工程类型:" >
              <el-cascader disabled
                v-model="typeIds"
                :options="typeIdList"
                :props = "{label:'name',value:'id'}"
                ></el-cascader>
            </el-form-item>
         
            <el-form-item label="名称:" prop="name">
              <el-input v-if="isEdit"
                v-model="form.name" 
                maxlength="10"
                placeholder="请输入名称"
              ></el-input>
              <el-input v-else
                v-model="form.name" disabled
                maxlength="10"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="埋深:" prop="depth"  >
              <el-input v-if="isEdit"
                v-model="form.depth"
                maxlength="10"
                placeholder="请输入埋深"
              ></el-input>
              <el-input disabled v-else
                v-model="form.depth"
                maxlength="10"
                placeholder="请输入埋深"
              ></el-input>
            </el-form-item>
            <el-form-item label="经纬度:"  prop="geometry">
              <el-input v-if="isEdit"
                v-model="form.geometry"
                :readonly="true"
                placeholder="请输入经纬度"
              ></el-input>
              <el-input v-else disabled
                v-model="form.geometry"
                :readonly="true"
                placeholder="请输入经纬度"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注:" >
              <el-input v-if="isEdit"
                v-model="form.remarks"
                maxlength="200"
                type="textarea"
                autosize
                placeholder="请输入内容"
              >
              </el-input>
              <el-input v-else disabled
                v-model="form.remarks"
                maxlength="200"
                type="textarea"
                autosize
                placeholder="请输入内容"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="现场照片:">
              <div v-if="!isEdit">
                <el-image 
                          style="width:80px;height:80px;margin-left:10px"
                          
                          :preview-src-list="listImg" :src="itemImg" v-for="itemImg,index in listImg" :key="index" z-index="index" >
                        </el-image>
              </div>
              <div v-else>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :http-request="onFileList"
                  :on-remove="onDeleteFileList"
                  :before-remove="beforHFhandleChangeImgDel"
                  :file-list="fileList"
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                  :limit="5"
                  :on-exceed="msgLimit"
                  :on-error="errorList"
                >
                  <i class="el-icon-plus" ></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisibleImg">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </el-form-item>
          
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
        <div class="btn">
          <el-button size="mini"  v-if="isEdit" @click="changeStatus()">取消</el-button>
          <el-button size="mini"  v-else @click="closeStatus()">取消</el-button>
          <el-button size="mini" type="primary" v-if="isEdit"  @click="saveadd">保存</el-button>
          <el-button size="mini" type="primary" v-else @click="eidtStatus">编辑</el-button>
        </div>
          </div>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
    <station-location ref="stationLocation"></station-location>
  </div>
</template>

<script>
import {selectDictListByParentCode, uploadImg, workHiddenUpdateDetailById} from '../../Gis/apis/commonType'
import {pageStandingBook,cancelWorkHidden,exportStandingBook,getWorkHiddenById} from '../../../RequestPort/gisbook/hidden.js'
import showImg from '../../gongfu/showImg.vue'
import { exportMethod } from '../../../common/js/exportExcel'
import StationLocation from '../stationLocation.vue'
import coverlayer from '../../Gis/apis/coverlayer'
export default {
  name: 'PressureLevel',
  components: {
    showImg,
    StationLocation
  },
  data() {
    return {
      total:0,
      pageParams:{current:1,size: 100},
      typeIdList:[],
      dataTime:[],
      typeIds2:[],
      typeIds:[],
      statusCodeList:[{value:'0',name:'正常'},{value:'1',name:'作废'}],
      tableData:[],
      imgList:[],
      dialogVisible:false,
      dialogVisibleImg:false,
      form:{imgKeys:[]},
      dialogImageUrl: '',
      repairBeforFilefileList: [],
      fileList:[],
      hiddenRules:{
        name:[{ required: true, message: '请填写隐蔽工程名称', trigger: 'blur' }],
        geometry:[{ required: true, message: '请输入经纬度', trigger: 'change' }],
        depth: [
          {
            required: true,
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请输入正确格式,可保留2位小数',
            trigger: 'blur'
          }
        ]
      },
      isEdit:false,
      formOld:{},
      listImg:[],
      optionsList:[],
      props: {
        emitPath: false,
        label: 'name',
        value: 'id'
      },
    }
  },
  props: {
    showType: {
      type: String,
      default: ''
    }
  },
  
  mounted() {
    this.getOption()
    this.getList(1)
  },
  methods: {
    async getOption(){
      let p = { sysType: 1}
        let res = await coverlayer.getFindTree(p)
        this.optionsList = res.data
      selectDictListByParentCode({level:2,parentCode:'ybgc'}).then(res=>{
        this.typeIdList = res.data
      })
    },
    
    async getList(page) {
      if(page){
        this.pageParams.current = 1
      }
      this.pageParams.sourceType = this.showType
      if(this.dataTime){
        this.pageParams.startTime = this.dataTime[0];
        this.pageParams.endTime = this.dataTime[1]
      }else{
        delete this.pageParams.startTime
        delete this.pageParams.endTime
      }
      if(this.typeIds2.length>0){
        this.pageParams.typeId = this.typeIds2[0]
        if(this.typeIds2[1]){
          this.pageParams.typeSubId = this.typeIds2[1]
        }
      }
      

      let res = await pageStandingBook(this.pageParams)
      this.tableData = res.data.records
      this.total = res.data.total
    },
    showImg(row){
          if(row.urlsList&&row.urlsList.length>0&&row.urlsList[0]){
             this.$refs.imgList.setdialogVisible(true);
             this.imgList = row.urlsList;
          }else{
              this.$message.error('暂无工程图片')
          }
          
      },
    restSearch(){
      this.pageParams = {current:1,size: 100}
      this.dataTime = [];
      this.typeIds = [];
      this.typeIds2=[]
      this.getList()
    },
    exportFile(){
      exportStandingBook(this.pageParams).then(res=>{
        exportMethod(res, '隐蔽工程')
      })
    },
    edit(row){
      this.dialogVisible = true
      this.isEdit = false
      getWorkHiddenById({id:row.id}).then(res=>{
        this.form = res.data
        if(res.data.photosUrl){
          this.fileList = []
          this.listImg=res.data.urlList
          res.data.urlList.forEach(el=>{
            this.fileList.push({url:el})
          })
          this.form.imgKeys = res.data.urlList
        }else{
          this.fileList = []
          this.listImg=[]
          this.form.imgKeys = []
        }
        this.form.geometry = row.point.replace('POINT(', '').replace(')', '')
        this.typeIds = [res.data.typeId]
        if(res.data.typeSubId){
          this.typeIds.push(res.data.typeSubId)
        }
        
      })
    },
    eidtStatus(){
      this.isEdit = true;
      this.formOld = this.form
    },
    changeStatus(){
      this.edit({id:this.form.id,point:this.formOld.point})
    },
    closeStatus(){
      this.dialogVisible = false
    },
    clearnF(){
      this.form = {imgKeys:[]}
      this.fileList = []
      this.listImg=[]
    },
    getDelete(row){
        this.$confirm(`是否确认作废【${row.name}】信息？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        cancelWorkHidden({id:row.id}).then(res=>{
          this.$message.success(res.msg);
          this.getList();
         })
      });
      
    },
    saveadd(){
      if(this.typeIds.length>0){
            this.form.typeId = this.typeIds[0]
            if(this.typeIds[1]){
              this.form.typeSubId = this.typeIds[1]
            }
          }
      this.form.urlList = this.form.imgKeys
      this.form.remark = this.form.remarks
      workHiddenUpdateDetailById(this.form).then(res=>{
        if(res.code=='200'){
          this.$message.success(res.msg)
          this.edit({id:this.form.id,point:this.formOld.point})
          this.getList();
          this.isEdit = false
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    dingwei(row){
      let data = {}
      getWorkHiddenById({id:row.id}).then(res=>{
        data = res.data
        if(res.data.photosUrl){
          data.urlList = res.data.photosUrl.split(',')
        }else{
          data.urlList = []
        }
        data.showType = 'hiddenDesc'
        data.title = '隐蔽工程'
        this.$refs.stationLocation.openDialog(data,row.point)
      })
      
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.getList(1);
    },
    /*
     ****************************************************************************************************
     * 功能描述：上传文件
     * 开发人： 黄丽玲
     * 开发时间： 2022-5-23
     *
     ****************************************************************************************************
     */
    async onFileList(files) {
      let uploadObj = new FormData()
      uploadObj.append('file', files.file)
      uploadObj.append('modelName', '草图')
      await uploadImg(uploadObj).then(res => {
        // this.item.imgKeys.push(res.data.key)
        this.form.imgKeys.push(res.data.url)
      })
    },
    beforHFhandleChangeImgDel(file, fileList) {
      this.repairBeforFilefileList = fileList
      let index = this.getArrayIndex(this.repairBeforFilefileList, file)
    
      this.form.imgKeys.splice(index, 1)
    },
    getArrayIndex(arr, obj) {
      var i = arr.length
      while (i--) {
        if (arr[i] === obj) {
          return i
        }
      }
      return -1
    },
    onDeleteFileList(file) {},
    errorList() {
      this.$message.error('上传失败！')
    },
     msgLimit(file, fileList) {
      this.$message.error('最多上传5张图片')
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: 90px;
  height: 90px;
  line-height: 90px;
  background: none;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 90px;
  height: 90px;
}
// ::v-deep .el-table--border, .el-table--group{
//       border: 1px solid #cbd6e7}
// ::v-deep .el-table thead{
//   color: #000;
// }
// ::v-deep .el-table{
//   color: #636b70;
// }
.pressure-box {
  // display: flex;
  .btns {
    margin-bottom: 10px;
  }
  .pressure-item {
    // flex: 1;
    // width: 600px;
  }
  .pepe-left {
    margin-right: 30px;
  }
  .tableBtn {
    .btn {
      cursor: pointer;
      margin: 0 3px;
      &.up {
        color: #1e9fff;
      }
      &.down {
        color: #0dba7b;
      }
    }
  }
}
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{    
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.btn{text-align: right;}
.search{
  width: 190px;
}
.contain{
  height: 690px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
</style>
