
import axios from '@/common/js/request'

export function pageStandingBook(data){
    const pageStandingBook = axios({
        url: '/gis/basic/sign/workHidden/pageStandingBook',
        method: 'get',
        params:data
    })
    return pageStandingBook
}
export function cancelWorkHidden(data){
    const cancelWorkHidden = axios({
        url: '/gis/basic/sign/workHidden/cancelWorkHidden',
        method: 'get',
        params:data
    })
    return cancelWorkHidden
}
export function exportStandingBook(data){
    const exportStandingBook = axios({
        url: '/gis/basic/sign/workHidden/exportStandingBook',
        method: 'get',
        params:data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return exportStandingBook
}
export function getWorkHiddenById(data){
    const getWorkHiddenById = axios({
        url: '/gis/basic/sign/workHidden/getWorkHiddenById',
        method: 'get',
        params:data
    })
    return getWorkHiddenById
}